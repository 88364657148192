<template>
  <!-- 水票管理 -->
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['水票管理']" :chooseCrumbData="1" reloadComponent="CommodityFileVue"></bread-crumb>

      <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case>

      <div class="handleBtnClass">
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="handleAddEdit(0)">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_add"></span>
              <span style="margin-left: 5px">新增水票</span>
            </div>
          </el-button>
        </div>
        <div style="margin-right: 5px">
          <el-button type="danger" size="mini" @click="deleteList">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_delete"></span>
              <span style="margin-left: 5px">批量删除</span>
            </div>
          </el-button>
        </div>
      </div>

      <div class="table">
        <!-- 表格 -->
        <div style="padding: 10px 0">
          <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5" @selection-change="handleSelectionChange">
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column prop="create_time" sortable label="创建日期" align="center">
            </el-table-column>
            <el-table-column prop="number" sortable label="编号" align="center">
            </el-table-column>
            <el-table-column label="名称" align="center">
              <template slot-scope="scope">
                <div>【{{ scope.row.water_type }}】{{ scope.row.wt_name }}</div>
              </template>
            </el-table-column>
            <el-table-column label="是否启用" align="center">
              <template slot-scope="scope">
                <span :style="{
                  backgroundColor: scope.row.isEnable ? '#5eb95e' : 'red',
                }" class="isWater">{{ scope.row.isEnable ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i class="el-icon-edit poiner" @click="handleAddEdit(scope.row.id)"></i>
                <span style="margin: 0 10px"></span>
                <i class="el-icon-delete poiner" @click="handleDelete(scope.row.id)"></i>
              </template>
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span v-if="isResult">显示第&nbsp;{{ (currentPage - 1) * 10 + 1 }}&nbsp;至&nbsp;{{
              (currentPage - 1) * 10 + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>
            <span v-if="!isResult">显示&nbsp;0&nbsp;项结果，共&nbsp;0&nbsp;项</span>
            <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="currentPage"
                @current-change="currentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-and-amend v-if="isShow == 1" :pageId="pageId" @close="onClose"></add-and-amend>
  </div>
</template>
<script>
export default {
  components: {
    addAndAmend: () => import("./childrens/addAndAmend.vue"),
  },
  data() {
    return {
      isShow: 0,

      searchData: {

        date: true,
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的商品名称",
        isInquireBtn: true,
      },

      tableData: [],
      isLoading: true,
      multipleSelection: [],
      isResult: true,
      tabletotalNum: 0,
      currentPage: 1,
      currentLength: 10,
      commercial_id: "",
      pageId: null,
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.inquireList();
  },
  computed: {},
  methods: {
    // 关闭子页面
    onClose(data) {
      this.isShow = data;
      this.inquireList();
    },
    // 多选数据 -- 表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页 -- 当前页改变传值
    currentChange(val) {
      this.currentPage = val;
      this.inquireList();
    },
    // 表格数据
    inquireList() {
      this.$http
        .post(
          "/water_ticket/lst",
          this.$qs.stringify({
            currentPage: this.currentPage,
            currentLength: this.currentLength,
            commercial_id: this.commercial_id,
          })
        )
        .then((res) => {
          this.isLoading = false;
          this.tableData = res.data.data;
          this.tabletotalNum = res.data.count;
        });
    },
    // 删除 -- 批量
    deleteList() {
      if (this.multipleSelection != "") {
        this.$confirm("是否删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const idArr = this.multipleSelection.map((item) => {
            return item.id;
          });
          this.$http
            .post(
              "/water_ticket/del",
              this.$qs.stringify({
                id: idArr,
              })
            )
            .then(() => {
              this.inquireList();
            });
        });
      } else {
        this.$message.error("请选择需删除的数据！");
      }
    },
    // 删除 -- 单条
    handleDelete(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(
              "/water_ticket/del",
              this.$qs.stringify({
                id,
              })
            )
            .then(() => {
              this.inquireList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查询传值
    inquireBtnClick(e) {
      this.inquireSearch(e);
    },
    // 后端数据 -- 查询
    inquireSearch(e) {
      this.$http
        .post(
          "/water_ticket/water_search",
          this.$qs.stringify({
            firstTime: e.dateRange[0],
            lastTime: e.dateRange[1],
            search: e.inquireValue,
          })
        )
        .then((res) => {
          this.tableData = res.data;
        });
    },
    // 新增和修改
    handleAddEdit(value) {
      this.isShow = 1;
      this.pageId = value;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    dialogTClick() {
      // console.log(this.ruleForm.type);
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="less">
.demo-ruleForm {
  width: 70%;
}

.isWater {
  padding: 2px;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  border-radius: 2px;
  color: #ffffff;
}
</style>
